import { useMemo, useState } from 'react';
import { useTranslations, Trans } from '@mentimeter/i18n';
import { useReferral } from '@mentimeter/auth';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Bold, H2 } from '../typography';
import { Link } from '../actions';
import { Section } from '../layout';
import { PlansPreviewGrid } from './components/plans-preview-grid';
import { usePlansConfig } from './hooks/usePlansConfig';
import { TabLink } from './components/EducationalProfessionalPillToggle';

interface PlansReferenceProps {
  heading?: string | undefined;
}

const MonthlyToggle = ({
  isEducation,
  setIsEducation,
}: {
  isEducation: boolean;
  setIsEducation: (value: boolean) => void;
}) => {
  const t = useTranslations('plansContentPackage');

  return (
    <>
      <Text
        display={['initial', null, 'none']}
        fontSize="125"
        textAlign="center"
      >
        {!isEducation ? (
          <Trans
            t={t}
            i18nKey="plans.teacher_or_student"
            components={{
              Bold: <Bold />,
              Link: <Link onClick={() => setIsEducation(true)} />,
            }}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="plans.for_professionals"
            components={{
              Bold: <Bold />,
              Link: <Link onClick={() => setIsEducation(false)} />,
            }}
          />
        )}
      </Text>
      <Box
        display={['none', null, 'inherit']}
        flexDirection="row"
        bg="neutralWeak"
        borderRadius="48px"
        p="space1"
        m="0 auto"
        alignItems="stretch"
      >
        <TabLink
          onClick={() => setIsEducation(false)}
          highlighted={!isEducation}
        >
          {t('plans.professional_link')}
        </TabLink>

        <TabLink onClick={() => setIsEducation(true)} highlighted={isEducation}>
          {t('plans.educational_link')}
        </TabLink>
      </Box>
    </>
  );
};

export const PlansReference = ({ heading }: PlansReferenceProps) => {
  const referral = useReferral();
  const [isEducation, setIsEducation] = useState(false);
  const config = usePlansConfig({ isEducation });

  const plansModified = useMemo(
    () =>
      config?.plans.map((plan) => {
        let href: string;
        let children: string;

        switch (plan.id) {
          case 'free': {
            href = `/auth/signup?referral=${referral}`;
            children = 'Get started';
            break;
          }
          case 'enterprise': {
            href = '/enterprise';
            children = 'Contact sales';
            break;
          }
          default: {
            href = '/plans';
            children = 'Learn more';
          }
        }
        return { ...plan, action: { ...plan.action, href, children } };
      }),
    [config?.plans, referral],
  );
  return (
    <Section alignItems="center" width="100%" px="space4" py="space16">
      {heading && (
        <H2 textAlign="center" mb="space10">
          {heading}
        </H2>
      )}
      <Box
        mb="space12"
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <MonthlyToggle
          isEducation={isEducation}
          setIsEducation={setIsEducation}
        />
      </Box>
      <PlansPreviewGrid plans={plansModified || []} />
    </Section>
  );
};

export default PlansReference;
