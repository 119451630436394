'use client';
import * as React from 'react';
import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { BLOCKS } from '@contentful/rich-text-types';
import MentiIframe from 'src/components/MentiIframe';
import { Carousel } from 'src/ui/blocks/carousel/Carousel';
import { Limit, Narrow, Section } from 'src/ui/layout';
import PlansReference from 'src/ui/plans/PlansReference';
import { Video } from 'src/ui/Video';
import type {
  IBlockAccordion,
  IBlockArticles,
  IBlockBody,
  IBlockBullets,
  IBlockCta,
  IBlockHighlight,
  IBlockReference,
  IBlockStatement,
  IBlockStories,
  IBlockTeam,
  IBlockTemplates,
  IBlockWebinars,
  IPageWebinarFields,
  ITemplateFields,
  ITemplatePageFields,
} from 'types/generated/contentful';
import { H2 } from 'src/ui/typography';
import { Accordion } from 'src/components/Accordion';
import type { JSX } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import User from '../components/User';
import {
  Articles,
  Body,
  Bullets,
  CTA,
  Highlight,
  Statement,
  Stories,
  Team,
  Templates,
  Webinars,
} from '../ui/blocks';
import { formatDate } from '../utils/formatDate';
import { linkActionFields } from '../utils/linkActionFields';
import { SubscribeToLegalPackageUpdates } from '../components/notifyUser';
import type { TemplateViewT } from '../ui/Template';
import type { TeamMemberT } from '../ui/blocks/Team';
import { CarouselContentRenderer } from './CarouselContentRenderer';
import {
  getDynamicContentfulHeading,
  getDynamicContentfulReferenceActions,
  getDynamicContentfulSubheading,
  getDynamicValueDependingOnUser,
} from './components';
import ContentfulSplitExperiment from './components/ContentfulSplitExperiment';
import {
  getContentfulAssetImageProps,
  getContentfulImageProps,
  getTemplatePageProps,
  getTemplateProps,
} from './utils/utils';
import { BodyRenderer } from './BodyRenderer';

function getDateToShow(date: string | undefined) {
  // No date from Contentful
  if (!date) return undefined;
  const futureDate = new Date(date).getTime() > new Date().getTime();
  // Date has passed
  if (!futureDate) return undefined;
  return formatDate({ date });
}

function getWebinar({
  title,
  subheading,
  date,
  badge,
  image,
  slug,
  mood,
  liveUrl,
  livestormUrl,
}: IPageWebinarFields) {
  return {
    title: title ?? '',
    subheading: subheading ?? '',
    date: getDateToShow(date),
    badge: badge ?? '',
    image: image.fields?.file.url ?? '',
    slug,
    liveUrl,
    livestormUrl,
    theme: mood
      ? {
          colors: mood.fields.colors,
        }
      : undefined,
  };
}

interface RenderParam {
  [key: string]: Options;
}

export interface RenderParams {
  renderNode?: RenderParam;
  renderMark?: RenderParam;
  renderText?: RenderParam;
}

const options = (params: RenderParams): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: () => null,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      // Cast target as Entry<T> since Options are badly defined
      const id = node.data.target?.sys.contentType?.sys.id;
      switch (id) {
        case 'blockReference': {
          const { fields } = node.data.target as IBlockReference;
          if (fields?.referenceId === 'test-mentimeter') {
            return (
              <User>
                {(user) => (
                  <MentiIframe
                    heading={getDynamicContentfulHeading({
                      hasUser: Boolean(user),
                      value: fields?.heading?.fields ?? null,
                    })}
                    subheading={getDynamicContentfulSubheading({
                      hasUser: Boolean(user),
                      value: fields?.subheading?.fields ?? null,
                    })}
                  />
                )}
              </User>
            );
          }

          if (fields?.referenceId === 'subscribeToLegalPackageUpdates') {
            return (
              <User>
                {(user) => (
                  <Section theme={{ colors: 'brand' }}>
                    <Narrow alignItems="center">
                      <Limit alignItems="center">
                        <H2 textAlign="center" mb={['space2', 'space4']}>
                          {getDynamicContentfulHeading({
                            hasUser: Boolean(user),
                            value: fields.heading?.fields ?? null,
                          })}
                        </H2>
                        <SubscribeToLegalPackageUpdates />
                      </Limit>
                    </Narrow>
                  </Section>
                )}
              </User>
            );
          }

          if (fields?.referenceId === 'plans-mentimeter') {
            return (
              <User>
                {(user) => (
                  <PlansReference
                    heading={getDynamicContentfulHeading({
                      hasUser: Boolean(user),
                      value: fields.heading?.fields ?? null,
                    })}
                  />
                )}
              </User>
            );
          }

          return null;
        }

        case 'blockAccordion': {
          const { fields } = node.data.target as IBlockAccordion;
          const accordionTitle = fields?.title ?? '';
          const accordionItems =
            fields?.accordion.map((entry) => {
              return {
                id: entry.sys.id,
                title: entry.fields?.title ?? '',
                description: entry.fields?.description ?? '',
              };
            }) ?? [];

          return (
            <Section>
              <Accordion
                title={accordionTitle}
                items={accordionItems}
                renderDescription={(description) => (
                  <BodyRenderer document={description} />
                )}
              />
            </Section>
          );
        }

        case 'blockArticles': {
          const { fields } = node.data.target as IBlockArticles;
          const articles =
            fields?.articles
              .map(({ fields }) => {
                return {
                  badge: fields?.badge?.fields?.text,
                  image: fields?.image
                    ? {
                        src: fields.image.fields.image.fields.file.url,
                        alt: fields.image.fields.alt,
                        caption: fields.image.fields.caption,
                        decoration: fields.image.fields.decoration,
                      }
                    : undefined,
                  heading: fields?.heading ?? '',
                  text: fields?.text ?? '',
                  href: fields?.href ?? '',
                };
              })
              .filter(Boolean) ?? [];

          return (
            <Articles
              articles={articles}
              body={fields?.body}
              variant={fields?.variant}
              centerBody={fields?.centerBody}
            />
          );
        }

        case 'blockBody': {
          const { fields } = node.data.target as IBlockBody;
          return (
            <Body
              body={fields?.body}
              layout={fields?.layout}
              params={params?.renderNode?.blockBody}
              theme={
                fields?.mood?.fields
                  ? { colors: fields.mood.fields.colors }
                  : undefined
              }
            />
          );
        }
        case 'blockBullets': {
          const { fields } = node.data.target as IBlockBullets;
          const bullets =
            fields?.bullets.map(({ fields }) => {
              return {
                bullet: fields?.bullet,
                heading: fields.heading,
                text: fields?.text,
                clickable: fields?.clickable,
                action: linkActionFields(fields?.action?.fields),
              };
            }) ?? [];
          return (
            <Bullets
              bullets={bullets}
              body={fields?.body}
              theme={
                fields?.mood?.fields
                  ? { colors: fields.mood.fields.colors }
                  : undefined
              }
              variant={fields?.variant}
              action={linkActionFields(fields?.action?.fields)}
            />
          );
        }
        case 'blockCta': {
          const { fields } = node.data.target as IBlockCta;
          return (
            <User>
              {(user) => (
                <CTA
                  heading={getDynamicContentfulHeading({
                    value: fields.heading.fields ?? null,
                    hasUser: Boolean(user),
                  })}
                  subheading={
                    fields?.subheading &&
                    getDynamicValueDependingOnUser({
                      value: fields.subheading.fields ?? null,
                      hasUser: Boolean(user),
                    })
                  }
                  actions={getDynamicContentfulReferenceActions({
                    value: fields.actions.fields ?? null,
                    hasUser: Boolean(user),
                  })}
                  theme={
                    fields?.mood?.fields
                      ? { colors: fields.mood.fields.colors }
                      : undefined
                  }
                />
              )}
            </User>
          );
        }
        case 'blockHighlight': {
          const { fields } = node.data.target as IBlockHighlight;
          return (
            <Highlight
              badge={fields?.badge}
              body={fields?.body}
              media={fields?.media}
              action={fields?.action}
              layout={fields?.layout}
              variant={fields?.variant}
              imageMaxWidth={fields.imageMaxWidth}
              mood={fields?.mood}
              imageVerticalAlignment={fields?.imageVerticalAlignment}
              useMinimumHeight={fields?.useMinimumHeight}
            />
          );
        }

        case 'blockStatement': {
          const { fields } = node.data.target as IBlockStatement;
          return (
            <Statement
              action={linkActionFields(fields?.action?.fields)}
              attribution={fields?.attribution}
              statement={fields?.statement ?? ''} // draft in Contentful
              image={
                fields?.image?.fields?.image?.fields
                  ? getContentfulImageProps(fields.image.fields)
                  : undefined
              }
              layout={fields?.layout}
              theme={
                fields?.mood?.fields
                  ? { colors: fields.mood.fields.colors }
                  : undefined
              }
            />
          );
        }
        case 'blockTeam':
          const { fields } = node.data.target as IBlockTeam;
          const cleanPersons = (fields?.persons
            .map(({ fields, sys }) => {
              // draft in Contentful
              if (!fields?.image.fields) {
                return null;
              }
              return {
                name: fields.name,
                lastName: fields.lastName,
                role: fields.role,
                description: fields.description,
                detailedDescription: fields.detailedDescription,
                image: {
                  ...getContentfulAssetImageProps(fields.image),
                  alt: `Image of ${fields.name}`,
                },
                id: sys.id,
              };
            })
            .filter(Boolean) ?? []) as TeamMemberT[];
          return (
            <Team
              body={fields?.body}
              persons={cleanPersons}
              theme={
                fields?.mood?.fields
                  ? { colors: fields.mood.fields.colors }
                  : undefined
              }
              variant={fields?.variant}
            />
          );
        case 'blockTemplates': {
          const { fields } = node.data.target as IBlockTemplates;
          const templates = (fields?.templates
            .map(({ fields }) => {
              // draft in Contentful
              if (!fields) {
                return null;
              }
              return 'template' in fields
                ? getTemplatePageProps(fields as ITemplatePageFields)
                : getTemplateProps(fields as ITemplateFields);
            })
            .filter(Boolean) ?? []) as TemplateViewT[];
          return (
            <Templates
              body={fields?.body}
              templates={templates}
              theme={
                fields?.mood?.fields
                  ? { colors: fields.mood.fields.colors }
                  : undefined
              }
            />
          );
        }
        case 'blockStories': {
          const { fields } = node.data.target as IBlockStories;

          const stories =
            fields?.stories.map((x) => ({
              title: x.fields?.story.fields?.title ?? '',
              image: x.fields?.story.fields?.image.fields?.file.url ?? '',
              slug: x.fields?.slug,
              logotype:
                x.fields?.story.fields?.logotype?.fields?.file.url ?? '',
              logotypeAlt:
                x.fields?.story?.fields?.logotype?.fields?.title ?? '',
              video: x.fields?.story.fields?.video,
            })) ?? [];

          return (
            <Stories
              stories={stories}
              body={fields?.body}
              theme={
                fields?.mood?.fields
                  ? { colors: fields.mood.fields.colors }
                  : undefined
              }
            />
          );
        }
        case 'blockWebinars': {
          const { fields } = node.data.target as IBlockWebinars;
          const webinars =
            (fields?.webinars &&
              fields?.webinars.map((webinar) => getWebinar(webinar.fields))) ??
            [];
          const webinarsFeatured =
            (fields?.webinarsFeatured &&
              fields?.webinarsFeatured.map((webinar) =>
                getWebinar(webinar.fields),
              )) ??
            [];
          return (
            <Webinars webinars={webinars} webinarsFeatured={webinarsFeatured} />
          );
        }
        case 'video': {
          const { fields } = node.data.target;
          if (
            !fields?.url ||
            node.data.target.sys.id === '4XAe9R47fNFMFoeZd5TBzL'
          ) {
            return null;
          }
          const videoProps = {
            preview: fields.preview?.fields?.file.url,
            url: fields.url,
            caption: fields.caption,
            title: fields.title,
            faded: fields.faded,
          };
          return (
            <Section py={undefined} pb={['space8', null, null, 'space16']}>
              <Box width="100%" justifyContent="center" alignItems="center">
                <Narrow>
                  <Video {...videoProps} />
                </Narrow>
              </Box>
            </Section>
          );
        }
        case 'blockCarousel': {
          const { fields } = node.data.target;

          if (!fields || !fields.content) return null;

          const content = CarouselContentRenderer({
            document: fields.content,
          }).filter(Boolean);

          return <Carousel title={fields.title} content={content} />;
        }
        case 'splitExperiment': {
          const { fields } = node.data.target;
          return <ContentfulSplitExperiment {...fields} />;
        }
        default:
          return null;
      }
    },
  },
});

interface Props {
  document: Document;
  params?: RenderParams;
}

export const PageRenderer = React.memo<Props>(({ document, params = {} }) => {
  return documentToReactComponents(document, options(params)) as JSX.Element;
});
