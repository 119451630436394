import React, { useState } from 'react';
import { ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { Wide } from 'src/ui/layout';
import { H2, H3 } from 'src/ui/typography';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Clickable } from '@mentimeter/ragnar-ui/clickable';
import { Collapsable } from '@mentimeter/ragnar-ui/collapsable';

interface AccordionT<T> {
  title: string;
  items: Array<{
    id?: string | undefined;
    title: string;
    description: T;
  }>;
  renderDescription: (description: T) => React.ReactElement;
}

export const Accordion = <T,>({
  title,
  items,
  renderDescription,
}: AccordionT<T>) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    if (index === expandedIndex) {
      setExpandedIndex(null); // Close the clicked item if it's already expanded
    } else {
      setExpandedIndex(index); // Expand the clicked item
    }
  };

  return (
    <Wide>
      <H2 px="space2">{title}</H2>
      {items.map(({ title, description }, index: number) => {
        return (
          <AccordionEntry
            key={title}
            title={title}
            description={description}
            index={index}
            renderDescription={renderDescription}
            isExpanded={expandedIndex === index}
            onClick={() => toggleItem(index)}
          />
        );
      })}
    </Wide>
  );
};

export const AccordionEntry = <T,>({
  title,
  description,
  index,
  renderDescription,
  isExpanded,
  onClick,
}: {
  title: string;
  description: T;
  index: number;
  renderDescription: (description: T) => React.ReactElement;
  isExpanded: boolean;
  onClick: (index: number) => void;
}) => {
  return (
    <Box alignItems="stretch" width="100%">
      <Clickable onClick={() => onClick(index)} aria-expanded={isExpanded}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          bg={isExpanded ? 'rgba(183, 186, 194, 0.3)' : 'transparent'}
          borderRadius={2}
          p="space2"
        >
          <H3 mb="space0" textAlign="start">
            {title}
          </H3>
          <Box
            mt="space1"
            extend={() => ({
              transition: 'transform 0.2s ease',
              transform: isExpanded ? 'rotate(-180deg)' : 'rotate(0)',
            })}
          >
            <ChevronDownIcon color="text" size={4} />
          </Box>
        </Box>
      </Clickable>
      <Collapsable show={isExpanded}>
        <Box
          width="100%"
          px="space2"
          extend={() => ({
            '> p': {
              marginTop: isExpanded ? '8px' : '0px',
              marginBottom: isExpanded ? '8px' : '0px',
              fontSize: '16px',
            },
          })}
        >
          {renderDescription(description)}
        </Box>
      </Collapsable>
    </Box>
  );
};
