'use client';
import type { ColorsEnum } from '@mentimeter/ragnar-dsc';
import { contentfulImageLoader } from 'src/cms/utils/utils';
import NextImage from 'src/components/image/NextImage';
import { Box } from '@mentimeter/ragnar-ui/box';

export interface AvatarT {
  url: string;
  alt: string;
  size?: number;
  bg?: ColorsEnum | undefined;
}

export function Avatar({ url, size, alt, bg }: AvatarT) {
  return (
    <Box borderRadius={3} overflow="hidden" bg={bg ? bg : 'neutralWeakest'}>
      <NextImage
        width={size ? size : 300}
        height={size ? size : 300}
        src={url}
        style={{
          borderRadius: 9999,
        }}
        alt={alt}
        loader={contentfulImageLoader}
      />
    </Box>
  );
}
